@import "../../../../client/styles/themes/setup";

#homeCarousel {
  position: absolute;
  z-index: 0;
  width: 100%;
  height: 100%;

  .carousel-inner {
    height: 100%;

    .carousel-item {
      display: block;
      width: 100%;
      height: 100%;
      transition-duration: 1s;
      transition-timing-function: ease-in-out;

      .image-wrapper {
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
      }

      .carousel-caption {
        bottom: 3rem;

        a {
          display: inline-block;
          padding-top: 1em;
          padding-bottom: 1em;
          padding-left: 2em;
          padding-right: 2em;
          text-decoration: none;
          color: #fff;
          background: rgb(0, 0, 0, 0.6);

          h1 {
            padding: 0;
            margin: 0;
            font-size: 3rem;
            font-weight: lighter;
            overflow-wrap: anywhere;

            @include media-breakpoint-down(sm) {
              font-size: 2rem;
            }
          }

          p {
            font-weight: lighter;
          }
        }
      }
    }
  }

  .carousel-control-prev-icon,
  .carousel-control-next-icon {
    width: 4em;
    height: 4em;
  }
}
